import React from 'react';

const Expertise = () => {
  return (
    <div>
      <h1>Expertise</h1>
    </div>
  );
};

export default Expertise;
